//=================================================
// Images
//=================================================

.img {
    &.object-fit {
        position: relative;
        
        &:before {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
        
        .object-fit-cover {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            outline: none;
            
            img, svg {
                width: 100% ;
                height: 100% ;
                object-fit: cover;
                -o-object-fit: cover;
            }
            
            figure {
                width: 100%;
                height: 100%;
            }
        }
        
        // Sizes
        &.size-1 {
            &:before {
                padding-top: 52%;
            }
        }
        
        &.size-2 {
            &:before {
                padding-top: 64%;
            }
        }
        
        &.size-3 {
            &:before {
                padding-top: 76%;
            }
        }
        
        &.size-4 {
            &:before {
                padding-top: 88%;
            }
        }
        
        &.size-5 {
            &:before {
                padding-top: 112%;
            }
        }
    }
}

// Blur filter
.img-blur {
    filter: blur(9px);
    -webkit-filter: blur(9px);
}

.img-gray {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

// Figure
.figure-img {
    margin-bottom: 0;
}

/*=============================
// Image size
=============================*/
.img-size-1 {
    .img {
        &.object-fit {
            min-height: 400px;
            max-height: 800px;

            &:before {
                padding-top: 41.7%;
            }
        }
    }
}
