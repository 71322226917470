.modal-offer {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-offer .modal-dialog.modal-lg.modal-dialog-centered {
  transform: none;
  background-color: white;
  border-radius: 24px;
}
.modal-offer .modal-dialog .modal-content {
  color: #6c6c6c;
  padding: 45px;
}
@media (min-width: 992px) {
  .modal-offer .modal-lg,
  .modal-xl {
    max-width: 705px;
  }
}
