//=================================================
// Page title
//=================================================

#page-title {
  .wrapper {
    position: relative;
    z-index: 1;
  }

  .text-max-width {
    max-width: 39.842rem;
  }

  &.with-img {
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    height: calc(100vh + 10.526rem);
    min-height: 784px;
    max-height: 1000px;

    .wrapper {
      height: 100%;
    }

    // Color
    color: $body_text_tertiary_color;

    [class*="widget-text-editor"] {
      color: $body_text_tertiary_color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    address,
    cit,
    code,
    del,
    dfn,
    em,
    ins,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    b,
    i,
    hr,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    legend,
    label,
    span,
    caption,
    figcaption,
    details,
    hgroup,
    summary,
    mark,
    audio,
    video {
      &:not(.btn) {
        color: inherit;
      }
    }

    a {
      &:not(.transform-scale-h):not(.btn) {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.page-title-bg-color {
  background-color: $body_main_element_secondary_color;
  position: absolute;
  opacity: 0.31;
  width: 100%;
  height: 100%;
  top: 0;
}
