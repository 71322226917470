/* Layout */
body {
  font-size: 16px !important;
  color: #566491 !important;
}
.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
  position: absolute;
}
header {
  grid-area: header;
  background-color: #203040;
}
main {
  grid-area: main;
  margin-left: 20%;
  padding-top: 0%;
}
footer {
  grid-area: footer;
  background-color: #203040;
  color: #ffffff;
}
/* Common */
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
  color: #566491 !important;
}
a {
  text-decoration: none;
}
a:hover {
  color: #ff8000;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li {
  margin-top: 1rem;
}
input,
select,
textarea,
button {
  padding: 1rem;
  border: 1px solid #ced4da !important;
  border-radius: 8px !important;
  font-size: 1.6rem;
  font-family: "Lora", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  background-color: #f8f8f8;
  cursor: pointer;
}
input:hover,
select:hover,
textarea:hover,
button:hover {
  border: 0.1rem #404040 solid;
}
button.primary {
  background-color: rgb(86, 100, 145);
}
button.block {
  width: 100%;
}
button.small {
  font-size: 1.2rem;
}
button.primary {
  background-color: rgb(86, 100, 145);
  color: white;
}
/* Header */
header a {
  color: #ffffff;
  padding: 7px;
}
a.brand {
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
}
.badge {
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 0px;
  margin-left: 0px;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 11 0 27.33333%;
  max-width: none;
}
.col-2 {
  -ms-flex: 2 1 50rem;
  flex: 2 1 48rem;
  max-width: none;
}
.col-3 {
  flex: 32 1 75rem;
}
.p-1 {
  padding: 1rem;
}
/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(-30rem);
  transition: all 0.5s;
}
aside.open {
  transform: translateX(0);
}
button.open-sidebar {
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  background: none;
  color: #ffffff;
  cursor: pointer;
}
button.open-sidebar:hover {
  border-color: #ffffff;
}
aside ul {
  padding: 0;
  list-style: none;
}
aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
button.close-sidebar {
  padding: 0.3rem 0.8rem;
}
/* Image */
img {
  border-radius: 0.5rem;
}
img.small {
  max-width: 8rem;
  width: 100%;
}
img.medium {
  max-width: 29rem;
  width: 100%;
}
img.large {
  width: 100%;
}
/* Card */
.card1 {
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  margin: 1rem;
}
.card-body1 {
  padding: 1rem;
}
.card-body1 > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 33px;
  font-weight: 800;
  font-family: "Lora", serif;
  color: #334c7d;
  margin-bottom: 6%;
}
input,
select,
textarea,
button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Rating */
.rating span {
  color: #f0c040;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #404040;
}
/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #28a745;
  font-weight: 600;
  margin-left: 16px;
}
.danger {
  color: #dc3545;
  font-weight: 600;
  margin-left: 16px;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
/* Form */
.form {
  max-width: 60rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.form label {
  margin: 1rem 0;
}
/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 3000;
  background-color: #566491;
  margin: 0;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #006e7f;
  color: #147484;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}
/* Carousel */
.carousel .slide img {
  max-width: 30rem;
}
.react-slideshow-container .default-nav:first-of-type {
  margin-right: 0px;
  /* border-right: 0; */
  /* border-top: 0; */
  border: none !important;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
.react-slideshow-container .default-nav:last-of-type {
  margin-right: 0px;
  /* border-right: 0; */
  /* border-top: 0; */
  border: none !important;
  background-color: transparent;
}
.react-slideshow-wrapper.slide {
  overflow: visible !important;
}
/* Search */
.search button {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right: none;
  margin-right: 0.5rem;
}
.search input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none;
  margin-left: 0.5rem;
}
.active {
  font-weight: bold;
}
/* Map */
.full-container {
  margin: -1rem;
  height: calc(100% + 2rem);
}
.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 4rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
/* Pagination */
.pagination a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: "Lora", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
}
/* Dashboard */
.summary > li {
  border: 0.1rem #c0c0c0 solid;
  margin: 2rem;
  border-radius: 0.5rem;
  flex: 1 1 20rem;
}
.summary-title {
  font-size: 2rem;
  padding: 1rem;
}
.summary-body {
  font-size: 4rem;
  padding: 1rem;
  text-align: center;
}
.summary-title.color1 {
  background-color: #f0e0e0;
}
.summary-title.color2 {
  background-color: #e0f0e0;
}
.summary-title.color3 {
  background-color: #e0e0f0;
}
/* Chatbox */
.chatbox {
  color: #000000;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}
.chatbox ul {
  overflow: scroll;
  max-height: 20rem;
}
.chatbox li {
  margin-bottom: 1rem;
}
.chatbox input {
  width: calc(100% - 9rem);
}
.support-users {
  background: #f0f0f0;
  height: 100%;
}
.support-users li {
  background-color: #f8f8f8;
}
.support-users button {
  background-color: transparent;
  border: none;
  text-align: left;
}
.support-users li {
  margin: 0;
  background-color: #f0f0f0;
  border-bottom: 0.1rem #c0c0c0 solid;
}
.support-users li:hover {
  background-color: #f0f0f0;
}
.support-users li.selected {
  background-color: #c0c0c0;
}
.support-messages {
  padding: 1rem;
}
.support-messages input {
  width: calc(100% - 9rem);
}
.support-messages ul {
  height: calc(100vh - 18rem);
  max-height: calc(100vh - 18rem);
  overflow: scroll;
}
.support-messages li {
  margin-bottom: 1rem;
}
.support-users span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  margin-left: -25px;
  margin-top: 10px;
}
.support-users .offline {
  background-color: #808080;
}
.support-users .online {
  background-color: #20a020;
}
.support-users .unread {
  background-color: #f02020;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}
i.fa.fa-caret-down {
  display: contents;
}
li.user {
  font-family: "Lora", serif;
  font-weight: 700;
  color: #060606;
  text-transform: uppercase;
  margin-top: 10%;
}
li.userInfo {
  font-family: "Lora", serif;
  font-weight: 700;
  color: #060606;
  text-transform: uppercase;
  margin-top: 8%;
}
.card-top-img {
  width: 50% !important;
}
.containerproduct {
  margin-top: 0%;
}
/* .colproduct{
  margin-top: 0%;
  height: 900px;
} */
.pro-sidebar {
  width: 330px;
  /* height: 900px!important; */
}
/* .pro-sidebar > .pro-sidebar-inner {
  height: 900px!important;
} */
main {
  padding-top: 0% !important;
  margin-left: 0% !important;
}
.card-img-top {
  width: 200px;
  height: 100px;
  object-fit: cover;
}
ul li:before {
  content: none;
}
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 500px;
}
.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.row.gutter-width-md {
  justify-content: space-around;
}
.img.object-fit .object-fit-cover img,
.img.object-fit .object-fit-cover svg {
  height: 100% !important;
}
.card {
  /* margin-top: 13px;
  margin-top: 13px;
  background-color: rgb(248, 248, 248); */
}
.card-title {
  color: #006e7f;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
}
/* QtyCardBox */
.product-item-inner {
  margin-bottom: 0;
}
@media (min-width: 640px) {
  .product-item-inner {
    margin: 9px -9px 0 -10px;
  }
}
.product-item-actions,
.products-grid.products.grid.flex-grid
  .product-item-details
  .product-item-actions,
.wishlist-index-index .box-tocart > fieldset {
  display: flex !important;
  justify-content: center;
}
.product-item-actions {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s;
}
.product-item-actions .actions-primary {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}
.product-item-actions .actions-primary form[data-role="tocart-form"] {
  display: flex;
}
form {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.qty-box {
  display: block;
  /* width: 88px; */
  margin: 0 auto 5px;
  margin-right: 3px !important;
  border: solid 1px #dfdfdf;
}
.qty-box .qtyminus,
.qty-box .qtyplus {
  position: relative;
  /* border: solid 1px #dfdfdf; */
  font-size: 12px;
  width: 30px;
  height: 37px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  /* float: left; */
  /* margin-right: -1px; */
}
.qty-box input {
  width: 45px;
  height: 30px;
  text-align: center;
  display: inline-block;
  /* border: solid 1px #dfdfdf; */
}
.qtyminus {
  border-right: none !important;
}
.product-item-actions .tocart {
  border: 0 none !important;
  /* background: #94c95d !important; */
  color: #fff !important;
  height: 29px !important;
  display: flex;
}
.card-img-top {
  align-self: center;
}
.input-text.qty {
  position: relative;
  font-size: 12px;
  width: 30px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
}
span.navbar-brand {
  display: inline-flex;
}
.nav-link {
  display: block;
  padding: 0.7895 rem 1.579 rem;
  font-family: "Lora", serif;
  font-weight: 500;
  color: #006e7f;
  font-size: 16px;
}
.spacer.p-top-xl {
  padding-top: 1.7 rem !important;
}
.ourteamimage {
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}
.ourteamimages {
  width: 29%;
  margin-top: 19px;
  height: 25%;
}
.ourteamimage_vision {
  width: 12%;
  margin-top: 6px;
  height: 56%;
}
@media (max-width: 425px) {
  .ourteamimage_vision {
    width: 37%;
  }
}
.slide {
  max-width: 50%;
  margin-left: 29%;
}
.dropdown-content {
  color: aliceblue !important;
  font-size: 16px;
  text-align: center;
}
@media (max-width: 767px) {
  .navbar-brand {
    display: flex;
    flex-flow: row wrap;
  }
}
@media (min-width: 768px) {
  .navbar-brand {
    display: flex;
    flex-direction: row;
  }
}
/* Testimonial */
.testimonialImage {
  height: 350px !important;
  max-width: 350px !important;
  border-radius: 10% !important;
}
.testimonialImageMobile {
  height: 150px !important;
  max-width: 150px !important;
  border-radius: 10% !important;
}
/* Side Bar Offer */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  background-color: #f9f9f9;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.openNav {
  width: 20%;
}
.selectProduct {
  width: 49%;
}
.addQty {
  width: 49% !important;
}
/* On print */
@media print {
  .hide-on-print {
    display: none;
  }
  .site-header {
    display: none;
  }
  .menu-secondary {
    display: none;
  }
}
/* Single Item */
.columnSingleItem {
  float: left;
  width: 33.33%;
  padding: 10px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.discountSingle {
  width: 6%;
  margin-left: 9%;
  height: 19%;
  position: "absolute";
  z-index: 1;
  margin-top: 11%;
}
/* Search */
.products_result {
  overflow-y: scroll;
  scroll-behaviour: smooth;
  height: 300px;
  font-size: large;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 12px;
}
/* Mobile Responsive */
@media (max-width: 450px) {
  .site-header .header-content .header-left {
    margin-right: -5rem;
  }
}
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .columnSingleItem {
    width: 100% !important;
  }
  .card.card-body.product_description {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
i.hosco-icon-call-2 {
  flex-grow: 4;
}
.informations {
  display: flex;
  justify-content: space-between;
  padding-top: 41px;
}
.ant-col-6 {
  display: flex;
  justify-content: center;
}
button.btn.btn-lg.btn-link.stretched-link.collapsed,
.accordion-services .btn-link {
  border: none !important;
}
.loadmore_button {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.product_dropdown_sort {
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-top: 4%;
}
.title_product {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.product_price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-family: "Lora", serif;
  font-weight: 500;
  margin-bottom: 10%;
}
@media only screen and (max-width: 600px) {
  .product_price {
    flex-direction: column;
  }
}
h2.our_service {
  color: white !important;
}
p.our_service {
  color: white !important;
}
.contacts_title {
  color: white !important;
}
.btn-primary {
  color: rgb(108, 108, 108);
  background-color: white;
  border-color: #b7ab9c;
}
button.ant-btn.ant-btn-default.ant-btn-lg.button_load {
  color: rgb(108, 108, 108) !important;
  background-color: white !important;
  border-color: #b7ab9c !important;
}
button.ant-btn.ant-btn-default.ant-btn-lg.button_load:hover {
  color: #ffffff !important;
  background-color: #a79886 !important;
}
button.ant-btn.ant-btn-default.ant-dropdown-trigger.button_load {
  color: rgb(108, 108, 108) !important;
  background-color: white !important;
  border-color: #b7ab9c !important;
  font-weight: 900;
}
button.ant-btn.ant-btn-default.ant-dropdown-trigger.button_load:hover {
  color: #ffffff !important;
  background-color: #a79886 !important;
}
.product_background {
  width: 100%;
  background-image: url("./assets/img/bg/dental_instruments.png");
  background-repeat: no-repeat;
  background-position: 50% 24%;
  background-size: cover;
  min-height: 320px;
  position: relative;
}
.overlay_product_background {
  background: #000;
  opacity: 0.7;
  min-height: 320px;
}
.container_product_background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
h2.shop_label {
  font-weight: 700;
  color: #fff !important;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.ant-tabs-nav {
  box-shadow: 0 0 0 1px rgb(67 41 163 / 8%), 0 1px 5px 0 rgb(67 41 163 / 8%);
  border-radius: 3px;
  background: #fff;
  height: fit-content;
}
/* .background_testimonial::before {
  background: linear-gradient(
    90deg,
    rgba(50, 76, 125, 0.66) 0,
    rgba(51, 76, 125, 0.8) 30%,
    rgba(51, 76, 125, 0.8) 60%,
    rgba(51, 76, 125, 0.66)
  ) !important;
} */
#testimonials {
  /* background-image: url(./assets/img/demo/13_img.png); */
  /* background-position: 50%; */
  /* position: relative; */
  background: url("../src/assets/img/demo/13_img.png");
  background-size: cover;
  background-position: center center;
}
.ant-pagination {
  margin-top: 14%;
  display: flex;
  justify-content: center;
}
.ant-pagination-item a {
  color: #000 !important;
}
li.ant-pagination-options {
  margin-top: -1%;
}
li.ant-pagination-next {
  width: 5%;
}
li.ant-pagination-prev {
  width: 5%;
}
span.anticon.anticon-left {
  display: flex;
  justify-content: center;
}
span.anticon.anticon-right {
  display: flex;
  justify-content: center;
}
.product_info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.addCartButton {
  /* width: 38% !important; */
  background-color: rgb(86, 100, 145);
  color: white;
  border-radius: 25px 25px 25px 25px !important;
  padding: 0.4375rem 1.875rem;
}
#main {
  background-color: #f8f8f8;
}
.addCartButton1 {
  width: 100% !important;
  background-color: rgb(86, 100, 145);
  color: white;
  border-radius: 25px 25px 25px 25px !important;
  padding: 0.4375rem 1.875rem;
}
/* .prediction {
  position: relative;
  padding: 20px 15px 20px 75px;
  transition: 0.5s;
  border-radius: 4px;
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-duration: 1s;
  animation-delay: 1s;
} */
.prediction {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.prediction.active {
  transform: translateY(0);
  opacity: 1;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-100px);
    -moz-transform: translatex(-100px);
    -o-transform: translatex(-100px);
    transform: translatex(-100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
.prediction_section {
  display: flex;
  justify-content: space-evenly !important;
  margin-left: 15% !important;
}
#our-team {
  background-image: url(./assets/img/placeholder/background_prediction.png);
  background-size: cover;
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  padding-bottom: 5%;
  height: 100%; */
}
.team-member-description {
  font-weight: 700;
}
h2.lang {
  color: #273b4a !important;
}
#clients-item-content img {
  max-height: 2.579rem !important;
  border-radius: 0% !important;
}
.image_product {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 35%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
a.imageSize {
  width: 200px !important;
  height: 200px !important;
}
