//=================================================
// Buttons
//=================================================

.btn {
  min-width: 11.053rem;

  &.btn-sm {
    min-width: 6.158rem;
  }

  &.btn-lg {
    min-width: 13.632rem;
  }

  &.btn-regular {
    font-family: $font_primary;
    font-weight: 400;
  }

  &.btn-bold {
    font-family: $font_primary_bold;
    font-weight: 700;
  }

  &.before {
    @include lhCrop-b(1.23, 0.75);
  }

  &.after {
    @include lhCrop-a(1.23, 0.83);
  }

  &.btn-link {
    color: $body_links_color;

    &:hover {
      color: $body_links_hover_color;
    }

    &.link-no-space {
      line-height: 0.9;
    }
  }

  &.btn-line {
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 0.158rem;
      background-color: $body_links_color;
      margin-top: 0.421rem;
      transition: background-color $transition-s ease-in-out;
    }

    &:hover {
      &:after {
        background-color: $body_links_hover_color;
      }
    }
  }

  &.btn-square {
    width: 3.421rem;
    height: 3.421rem;
  }
}

// Button size
.btn-size {
  font-size: $f_size_df;
}

.btn-sm-size {
  font-size: $f_size_sm;
}

.btn-lg-size {
  font-size: $f_size_lg;
}

// Block button
.has-text-color {
  &.has-white-color {
    color: $white !important;
  }

  &.has-gray-color {
    color: $gray !important;
  }

  &.has-gray-light-color {
    color: $gray-light !important;
  }

  &.has-gray-dark-color {
    color: $gray-dark !important;
  }

  &.has-blue-color {
    color: $blue !important;
  }

  &.has-indigo-color {
    color: $indigo !important;
  }

  &.has-purple-color {
    color: $purple !important;
  }

  &.has-pink-color {
    color: $pink !important;
  }

  &.has-red-color {
    color: $red !important;
  }

  &.has-orange-color {
    color: $orange !important;
  }

  &.has-yellow-color {
    color: $yellow !important;
  }

  &.has-green-color {
    color: $green !important;
  }

  &.has-teal-color {
    color: $teal !important;
  }

  &.has-cyan-color {
    color: $cyan !important;
  }
}

.has-background {
  &.has-white-background-color {
    background-color: $white !important;
  }

  &.has-gray-background-color {
    background-color: $gray !important;
  }

  &.has-gray-light-background-color {
    background-color: $gray-light !important;
  }

  &.has-gray-dark-background-color {
    background-color: $gray-dark !important;
  }

  &.has-blue-background-color {
    background-color: $blue !important;
  }

  &.has-indigo-background-color {
    background-color: $indigo !important;
  }

  &.has-purple-background-color {
    background-color: $purple !important;
  }

  &.has-pink-background-color {
    background-color: $pink !important;
  }

  &.has-red-background-color {
    background-color: $red !important;
  }

  &.has-orange-background-color {
    background-color: $orange !important;
  }

  &.has-yellow-background-color {
    background-color: $yellow !important;
  }

  &.has-green-background-color {
    background-color: $green !important;
  }

  &.has-teal-background-color {
    background-color: $teal !important;
  }

  &.has-cyan-background-color {
    background-color: $cyan !important;
  }
}

// Default
button {
  &:focus {
    outline: 0;
  }
}

/*=============================
// Button group
=============================*/
.btn-group {
  .btn {
    min-width: auto;
  }
}
